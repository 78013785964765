import Cookies from 'js-cookie';

const setAuthUser = user => {
  if (user) {
    localStorage.setItem('auth_user', user);
  } else {
    localStorage.removeItem('auth_user');
    let sessionCookie = Cookies.get('opportunity_session');
    if (sessionCookie) {
      Cookies.set('opportunity_session', { expires: new Date(0) });
      Cookies.remove('opportunity_session');
    }
  }
};

export default setAuthUser;
