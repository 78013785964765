import {
  SECTOR_PERFORMANCE_ERROR,
  SECTOR_PERFORMANCE_LOADED,
  CLEAR_MARKET
} from '../actions/types';

const initialState = {
  sectorPerformance: null,
  loading: true,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SECTOR_PERFORMANCE_LOADED:
      return {
        ...state,
        sectorPerformance: payload,
        loading: false
      };
    case SECTOR_PERFORMANCE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_MARKET:
      return {
        sectorPerformance: null,
        loading: true
      };
    default:
      return state;
  }
}
