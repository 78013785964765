import api from '../utils/api';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  CLEAR_TICKERS,
  CLEAR_PORTFOLIO,
  CLEAR_MARKET
} from './types';
import { setAlert } from '../actions/alert';
import { stopSubmittingForm } from '../actions/ui';

export const loadUser = () => async dispatch => {
  try {
    const res = await api.get('/user/checkAuth');
    if (res.data.user !== null) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.user
      });
    } else {
      throw res;
    }
  } catch (error) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

export const register = values => async dispatch => {
  try {
    const body = JSON.stringify(values);
    const res = await api.post('/user/register', body);
    if (res.data.id)
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.id
      });
  } catch (error) {
    dispatch(stopSubmittingForm());
    dispatch(setAlert('Error occurred during registration', 'error'));
    dispatch({
      type: REGISTER_FAIL,
      message: error.response.data.error
    });
  }
};

export const login = values => async dispatch => {
  try {
    const body = JSON.stringify(values);
    const res = await api.post('/user/login', body);
    if (res.data.id)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.id
      });
  } catch (error) {
    if (!error.response.data.errors) {
      dispatch(stopSubmittingForm());
      dispatch(setAlert(error.response.data.error, 'error'));
      dispatch({
        type: LOGIN_FAIL,
        message: error.response.data.error
      });
    } else {
      const arr = error.response.data.errors;
      dispatch(stopSubmittingForm());
      for (let i = 0, l = arr.length; i < l; i++) {
        for (const [, value] of Object.entries(arr[i]))
          dispatch(setAlert(value, 'error'));
      }

      dispatch({
        type: LOGIN_FAIL,
        message: error.response.data.error
      });
    }
  }
};

export const logout = () => async dispatch => {
  try {
    const res = await api.post('/user/logout');
    if (res.data && res.data.success) {
      dispatch({
        type: LOGOUT
      });
      dispatch({
        type: CLEAR_PROFILE
      });
      dispatch({
        type: CLEAR_TICKERS
      });
      dispatch({
        type: CLEAR_PORTFOLIO
      });
      dispatch({
        type: CLEAR_MARKET
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL
    });
  }
};
