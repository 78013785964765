import { SUBMITTING_FORM_START, SUBMITTING_FORM_STOP } from './types';
export const startSubmittingForm = () => dispatch => {
  dispatch({
    type: SUBMITTING_FORM_START
  });
};

export const stopSubmittingForm = () => dispatch => {
  dispatch({
    type: SUBMITTING_FORM_STOP
  });
};
