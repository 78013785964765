import { combineReducers } from 'redux';
import ui from './ui';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import ticker from './ticker';
import portfolio from './portfolio';
import market from './market';
import tickerDetails from './tickerDetails';

export default combineReducers({
  ui,
  alert,
  auth,
  profile,
  ticker,
  portfolio,
  market,
  tickerDetails
});
