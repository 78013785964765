import { colors } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingRight: "1rem",
        paddingBottom: 0,
        "&:last-child": {
          paddingRight: "1rem"
        }
      }
    },
    MuiTableRow: {
      root: {
        height: "2.2rem",
        "&:last-child td": {
          borderBottom: 0
        }
      }
    }
  },
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: "#293250"
    },
    secondary: {
      main: "#293250"
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

export default theme;
