import {
  TICKER_OVERVIEW_LOADED,
  TICKER_OVERVIEW_ERROR,
  CLEAR_TICKER_OVERVIEW,
  KEY_METRICS_LOADED,
  CLEAR_KEY_METRICS,
  KEY_METRICS_ERROR,
  FINANCIAL_RATIOS_LOADED,
  CLEAR_FINANCIAL_RATIOS,
  FINANCIAL_RATIOS_ERROR
} from '../actions/types';

const initialState = {
  overview: null,
  financialRatios: null,
  keyMetrics: null,
  overviewLoading: true,
  keyMetricsLoading: true,
  financialRatiosLoading: true,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TICKER_OVERVIEW_LOADED:
      return {
        ...state,
        overview: payload,
        overviewLoading: false,
        keyMetricsLoading: true,
        financialRatiosLoading: true
      };
    case TICKER_OVERVIEW_ERROR:
      return {
        ...state,
        error: payload,
        overviewLoading: false
      };
    case CLEAR_TICKER_OVERVIEW:
      return {
        ...state,
        overview: null,
        overviewLoading: true
      };
    case KEY_METRICS_LOADED:
      return {
        ...state,
        keyMetrics: payload,
        keyMetricsLoading: false
      };
    case KEY_METRICS_ERROR:
      return {
        ...state,
        error: payload,
        keyMetrics: null,
        keyMetricsLoading: false
      };
    case CLEAR_KEY_METRICS:
      return {
        ...state,
        keyMetrics: null,
        keyMetricsLoading: true
      };
    case FINANCIAL_RATIOS_LOADED:
      return {
        ...state,
        financialRatios: payload,
        financialRatiosLoading: false
      };
    case FINANCIAL_RATIOS_ERROR:
      return {
        ...state,
        error: payload,
        financialRatios: null,
        financialRatiosLoading: false
      };
    case CLEAR_FINANCIAL_RATIOS:
      return {
        ...state,
        financialRatios: null,
        financialRatiosLoading: true
      };
    default:
      return state;
  }
}
