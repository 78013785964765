export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const PROFILE_LOADED = 'PROFILE_LOADED';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const REDIRECT = 'REDIRECT';
export const SUBMITTING_FORM_START = 'SUBMITTING_FORM_START';
export const SUBMITTING_FORM_STOP = 'SUBMITTING_FORM_STOP';

export const TICKER_LOADED = 'TICKER_LOADED';
export const TICKER_ERROR = 'TICKER_ERROR';
export const TICKER_SEARCHED = 'TICKER_SEARCHED';
export const TICKER_SEARCHED_ERROR = 'TICKER_SEARCHED_ERROR';
export const CLEAR_TICKER_SEARCH = 'CLEAR_TICKER_SEARCH';
export const CLEAR_TICKERS = 'CLEAR_TICKERS';
export const START_LOAD_TICKER = 'START_LOAD_TICKER';
export const NEW_TICKER_SEARCH = 'NEW_TICKER_SEARCH';
export const SET_TICKER_PAGE = 'SET_TICKER_PAGE';
export const SET_TICKER_SKIP = 'SET_TICKER_SKIP';
export const SET_TICKER_UPPERBOUND = 'SET_TICKER_UPPERBOUND';

export const PORTFOLIO_LOADED = 'PORTFOLIO_LOADED';
export const PORTFOLIO_ERROR = 'PORTFOLIO_ERROR';
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const ADD_ASSET_FAIL = 'ADD_ASSET_FAIL';
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_FAIL = 'UPDATE_PORTFOLIO_FAIL';
export const CLEAR_PORTFOLIO = 'CLEAR_PORTFOLIO';
export const DELETE_PORTFOLIO_ENTRY_SUCCESS = 'DELETE_PORTFOLIO_ENTRY_SUCCESS';
export const DELETE_PORTFOLIO_ENTRY_FAIL = 'DELETE_PORTFOLIO_ENTRY_FAIL';

export const SECTOR_PERFORMANCE_LOADED = 'SECTOR_PERFORMANCE_LOADED';
export const SECTOR_PERFORMANCE_ERROR = 'SECTOR_PERFORMANCE_ERROR';
export const CLEAR_MARKET = 'CLEAR_MARKET';

export const TICKER_OVERVIEW_LOADED = 'TICKER_OVERVIEW_LOADED';
export const TICKER_OVERVIEW_ERROR = 'TICKER_OVERVIEW_ERROR';
export const CLEAR_TICKER_OVERVIEW = 'CLEAR_TICKER_OVERVIEW';

export const KEY_METRICS_LOADED = 'KEY_METRICS_LOADED';
export const KEY_METRICS_ERROR = 'KEY_METRICS_ERROR';
export const CLEAR_KEY_METRICS = 'CLEAR_KEY_METRICS';

export const FINANCIAL_RATIOS_LOADED = 'FINANCIAL_RATIOS_LOADED';
export const FINANCIAL_RATIOS_ERROR = 'FINANCIAL_RATIOS_ERROR';
export const CLEAR_FINANCIAL_RATIOS = 'CLEAR_FINANCIAL_RATIOS';
