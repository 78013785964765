import React, { Suspense, lazy } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

const PublicRouteAuthCheck = lazy(() =>
  import("../routing/PublicRouteAuthCheck")
);
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const LoginView = lazy(() => import("../views/auth/LoginView"));
const RegisterView = lazy(() => import("../views/auth/RegisterView"));
const NotFoundView = lazy(() => import("../views/errors/NotFoundView"));

const PrivateRoute = lazy(() => import("../routing/PrivateRoute"));
const DashboardLayout = lazy(() => import("../layouts/DashboardLayout"));
const AccountView = lazy(() => import("../views/account/AccountView"));
const PortfolioListView = lazy(() =>
  import("../views/portfolio/PortfolioListView")
);
const DashboardView = lazy(() => import("../views/reports/DashboardView"));
const TickerListView = lazy(() => import("../views/ticker/TickerListView"));
const TickerDetailsView = lazy(() => import("../views/tickerDetailsView"));

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={null}>
              <MainLayout />
            </Suspense>
          }
        >
          <Route path="/" element={<Navigate to="/signin" replace={true} />} />
          <Route
            path="signin"
            element={
              <PublicRouteAuthCheck>
                <Suspense fallback={null}>
                  <LoginView />
                </Suspense>
              </PublicRouteAuthCheck>
            }
          />
          <Route
            path="register"
            element={
              <PublicRouteAuthCheck>
                <Suspense fallback={null}>
                  <RegisterView />
                </Suspense>
              </PublicRouteAuthCheck>
            }
          />
        </Route>

        <Route
          path="/app"
          element={
            <Suspense fallback={null}>
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            </Suspense>
          }
        >
          <Route
            path="/app"
            element={<Navigate to="dashboard" replace={true} />}
          />
          <Route
            path="account"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <AccountView />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="portfolio"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <PortfolioListView />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <DashboardView />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path="tickers"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <TickerListView />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="tickers/:exchange/:symbol/:detailType"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <TickerDetailsView />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <Suspense fallback={null}>
              <NotFoundView />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
