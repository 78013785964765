import {
  SUBMITTING_FORM_START,
  SUBMITTING_FORM_STOP,
  REDIRECT
} from '../actions/types';

const initialState = {
  isSubmitting: false,
  redirect: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUBMITTING_FORM_START:
      return {
        ...state,
        isSubmitting: true
      };
    case SUBMITTING_FORM_STOP:
      return {
        ...state,
        isSubmitting: false
      };
    case REDIRECT:
      return {
        ...state,
        redirect: payload
      };
    default:
      return state;
  }
}
