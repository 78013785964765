import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import GlobalStyles from "../src/components/GlobalStyles";
import "../src/mixins/chartjs";
import theme from "../src/theme";
import Routing from "./routing/routes";
import { Provider } from "react-redux";
import store from "./store";
import Alert from "./layouts/Alert";
import { loadUser } from "./actions/auth";
import { LOGOUT } from "./actions/types";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    window.addEventListener("storage", () => {
      if (!localStorage.user) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        <Alert />
        <Routing />
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
