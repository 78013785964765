import {
  TICKER_LOADED,
  TICKER_ERROR,
  TICKER_SEARCHED,
  TICKER_SEARCHED_ERROR,
  CLEAR_TICKER_SEARCH,
  START_LOAD_TICKER,
  NEW_TICKER_SEARCH,
  SET_TICKER_PAGE,
  SET_TICKER_SKIP,
  SET_TICKER_UPPERBOUND,
  CLEAR_TICKERS
} from '../actions/types';

const initialState = {
  ticker: null,
  loading: true,
  error: null,
  searched: false,
  newTickerSearch: false,
  page: 1,
  skip: 0,
  upperBound: 9
};

export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case TICKER_LOADED:
      return {
        ...state,
        ticker: payload,
        count: count,
        loading: false
      };
    case TICKER_SEARCHED:
      return {
        ...state,
        ticker: payload,
        count: count,
        loading: false,
        searched: true,
        newTickerSearch: false
      };
    case NEW_TICKER_SEARCH:
      return {
        ...state,
        searched: true,
        newTickerSearch: true
      };
    case START_LOAD_TICKER:
      return {
        ...state,
        loading: true
      };
    case CLEAR_TICKER_SEARCH:
      return {
        ...state,
        searched: false,
        loading: true
      };
    case SET_TICKER_PAGE:
      return {
        ...state,
        page: payload
      };
    case SET_TICKER_SKIP:
      return {
        ...state,
        skip: payload
      };
    case SET_TICKER_UPPERBOUND:
      return {
        ...state,
        upperBound: payload
      };
    case TICKER_ERROR:
    case TICKER_SEARCHED_ERROR:
      return {
        ...state,
        loading: false
      };
    case CLEAR_TICKERS:
      return {
        ...state,
        ticker: null,
        loading: true
      };
    default:
      return {
        ...state
      };
  }
}
