import React from 'react';
import PropTypes from 'prop-types';
// Interact component with redux for action/state.
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

const AlertLayout = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    // <div key={alert.id} class={`alert alert-${alert.alertType}`}>
    //   {alert.msg}

    <Snackbar key={alert.id} open={true}>
      <Alert severity={alert.alertType}>{alert.msg}</Alert>
    </Snackbar>
  ));

AlertLayout.propTypes = {
  // Shortcut (ptar) - propType array required
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

// Connect - Have mapStateToProps.
export default connect(mapStateToProps)(AlertLayout);
