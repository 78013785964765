import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }
  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <div
          style={{
            margin: '3rem',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            minWidth: '0',
            wordWrap: 'break-word',
            backgroundColor: '#fff',
            backgroundClip: 'border-box',
            border: '1px solid rgba(0,0,0,.125)',
            borderRadius: '.25rem'
          }}
        >
          <div
            style={{
              borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0',
              padding: '.75rem 1.25rem',
              marginBottom: '0',
              backgroundColor: 'rgba(0,0,0,.03)',
              borderBottom: '1px solid rgba(0,0,0,.125)'
            }}
          >
            <h1>Oops, something went wrong.</h1>
            <span
              style={{ cursor: 'pointer', color: '#0077FF' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload this page
            </span>
          </div>
          <div
            style={{ flex: '1 1 auto', minHeight: '1px', padding: '1.25rem' }}
          >
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <summary>View errors</summary>
              {errorInfo && errorInfo.componentStack.toString()}
            </details>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default ErrorBoundary;
