import {
  PORTFOLIO_LOADED,
  PORTFOLIO_ERROR,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAIL,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAIL,
  CLEAR_PORTFOLIO,
  DELETE_PORTFOLIO_ENTRY_SUCCESS,
  DELETE_PORTFOLIO_ENTRY_FAIL
} from '../actions/types';

const initialState = {
  portfolio: null,
  loading: true,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PORTFOLIO_LOADED:
    case UPDATE_PORTFOLIO_SUCCESS:
    case DELETE_PORTFOLIO_ENTRY_SUCCESS:
    case ADD_ASSET_SUCCESS:
      return {
        ...state,
        portfolio: payload,
        loading: false
      };
    case PORTFOLIO_ERROR:
    case UPDATE_PORTFOLIO_FAIL:
    case DELETE_PORTFOLIO_ENTRY_FAIL:
    case ADD_ASSET_FAIL:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_PORTFOLIO:
      return {
        portfolio: null,
        loading: true
      };
    default:
      return state;
  }
}
